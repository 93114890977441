export default function timeFormat(videojs) {
  videojs.registerPlugin('timeFormat', function() {
    videojs.deformatTime = function(time) {
      if (typeof time !== 'string') return 0;

      var timeFormat = /^(?:(?:(\d+):)?(\d?\d):)?(\d?\d)$/;
      var formatted = $.trim(time).match(timeFormat);

      if (formatted) {
        var hours = parseInt(formatted[1], 10) || 0;
        var minutes = parseInt(formatted[2], 10) || 0;
        var seconds = parseInt(formatted[3], 10) || 0;

        return seconds + minutes * 60 + hours * 60 * 60;
      } else {
        return parseInt(time, 10) || 0;
      }
    };
  });
}
