export default function playerAppearsWhenReady(videojs) {
  videojs.registerPlugin('playerAppearsWhenReady', function() {
    var player = this;

    player.on('ready', function() {
      player.el().style.visibility = 'visible';
      $(player.el())
        .find('video')
        .css('visibility', 'visible');
    });
  });
}
