export default function videoJsResolutionSwitcherExtension(videojs) {
  function hasClass(el, className) {
    if (el.classList) return el.classList.contains(className);
    else
      return !!el.className.match(
        new RegExp('(\\s|^)' + className + '(\\s|$)'),
      );
  }

  function addClass(el, className) {
    if (el.classList) el.classList.add(className);
    else if (!hasClass(el, className)) el.className += ' ' + className;
  }

  function removeClass(el, className) {
    if (el.classList) el.classList.remove(className);
    else if (hasClass(el, className)) {
      var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
      el.className = el.className.replace(reg, ' ');
    }
  }

  videojs.registerPlugin('videoJsResolutionSwitcherExtension', function() {
    var player = this;

    // OPTIMIZE: Find a way to avoid jquery
    player.one('play', function() {
      var qualitySelectorButton = $(player.el()).find('.vjs-quality-selector');

      qualitySelectorButton.on('touchstart', function(e) {
        if (hasClass(e.target, 'is-active')) {
          removeClass(e.target, 'is-active');
        } else {
          addClass(e.target, 'is-active');
        }
      });
    });

    // OPTIMIZE: This is a quickfix since the 'tip' can disappear when the source is changed
    player.on('play', function() {
      var resolutionMenu = $(player.el())
        .find('.vjs-quality-selector')
        .find('.vjs-menu')[0];

      !hasClass(resolutionMenu, '.vjs-tip') &&
        $(resolutionMenu).append('<div class="vjs-tip"></div>');
    });
  });
}
