/**
 *
 */

export default function videoViewTracker(videojs) {
  videojs.registerPlugin('videoViewTracker', function(options) {
    var url = options.video_view_attributes.video_view_url_array.join('/');

    if (url) {
      var player = this;
      var topic = options.video_view_attributes.topic;
      var assignment_id = options.video_view_attributes.assignment_id;

      player.on('timeupdate', function() {
        player.trigger('timeupdate.trackVideoView');
      });

      new Sofatutor.Classes.VideoViewsTracker(
        url,
        topic,
        player,
        assignment_id
      ).track();
    }
  });
}
