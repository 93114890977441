export default function bigPlayToggle(videojs) {
  videojs.registerPlugin('bigPlayToggle', function() {
    var player = this;
    var playToggle = null;

    var createPlayToggle = function() {
      playToggle = $('<div class="vjs-big-play-toggle"></div>');
      $(player.el()).append(playToggle);
    };

    var flash = function() {
      playToggle.addClass('is-fading is-scaling');

      setTimeout(function() {
        playToggle.removeClass('is-fading');
      }, 300);
      setTimeout(function() {
        playToggle.removeClass('is-scaling');
      }, 600);
    };

    var onClick = function(e) {
      if (
        $(e.target).closest('.vjs-control-bar, .timed-comment-wrapper')
          .length === 0
      ) {
        if (player.paused() || player.ended()) {
          player.trigger('pause-clicked');
        } else {
          player.trigger('play-clicked');
        }
      }
    };

    player.ready(function() {
      createPlayToggle();
      $(player.el())
        .closest('.new-player')
        .on('click', onClick);
      player.on(['pause-clicked', 'play-clicked'], flash);
    });
  });
}
