/**
 * This sofatutor-plugin hides conflicting page elements on fullscreen
 */

export default function hideOnFullscreen(videojs) {
  videojs.registerPlugin('hideOnFullscreen', function(options) {
    var player = this;
    var hideOnFullscreen = options.hide_on_fullscreen;

    player.on('fullscreenchange', function() {
      if (Sofatutor.features.homeworkChat) {
        if (player.isFullscreen()) {
          Sofatutor.features.homeworkChat.hideChatTrigger();
        } else {
          Sofatutor.features.homeworkChat.showChatTrigger();
        }
      }
    });
  });
}
