export default function showFullDuration(videojs) {
  videojs.registerPlugin('showFullDuration', function(options) {
    var duration = options.duration;

    videojs
      .getComponent('ControlBar')
      .getComponent('DurationDisplay').prototype.updateContent = function() {
      if (duration) {
        var localizedText = this.localize('Duration Time');
        var formattedTime = videojs.formatTime(duration);
        this.contentEl_.innerHTML =
          '<span class="vjs-control-text">' +
          localizedText +
          '</span> ' +
          formattedTime; // label the duration time for screen reader users
      }
    };
  });
}
