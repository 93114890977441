// This takes care of the presence and ordering of the player components.
export default function videoJsConfiguration(videojs) {
  require('silvermine-videojs-quality-selector')(videojs);

  videojs.getComponent('ControlBar').prototype.options_ = {
    children: {
      playToggle: {},
      currentTimeDisplay: {},
      progressControl: {},
      durationDisplay: {},
      playbackRateMenuButton: {},
      qualitySelector: {},
      volumePanel: {
        volumeBar: {
          vertical: true,
          inline: false,
        },
      },
      fullscreenToggle: {},
      SubsCapsButton: {},
    },
  };
}
