export default function volumeBarTip(videojs) {
  videojs.registerPlugin('volumeBarTip', function() {
    var player = this;

    this.ready(function() {
      var muteToggle = player
        .getChild('controlBar')
        .getChild('volumePanel')
        .getChild('MuteToggle');

      $(muteToggle.el_).append('<div class="vjs-tip"></div>');
    });
  });
}
