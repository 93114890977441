export default function fixPlayPauseToggle(videojs) {
  'use strict';

  videojs.registerPlugin('fixPlayPauseToggle', function() {
    var player = this;

    player.ready(function() {
      player.one('play', function() {
        var playToggle = player.controlBar.playToggle;

        playToggle.on(player, 'play', playToggle.handlePlay);
        playToggle.on(player, 'pause', playToggle.handlePause);
      });
    });
  });
}
