import 'videojs-hotkeys';

export default function hotkeysSetup(videojs) {
  videojs.registerPlugin('hotkeysSetup', function() {
    var player = this;

    player.ready(function() {
      // In the information page with multiple videos the last player is missing hotkeys function.
      if (player.hotkeys) {
        player.hotkeys({
          volumeStep: 0.25,
          seekStep: 5,
          enableVolumeScroll: false
        });
      }
    });
  });
}
