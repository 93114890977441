/*
 * This plugin takes care of error handling in the videojs player.
 */
export default function errorMessages(videojs) {
  videojs.registerPlugin('errorMessages', function(options) {
    var player = this;

    var sourceTags = $(player.el()).find('source');
    var sources = [];

    sourceTags.each(function(index) {
      sources[index] = { src: this.src, type: this.type };
    });

    if (!this.selectSource(sources)) {
      videojs.log('No compatible source and playback technology were found.');
      $('.new-player').append(options.no_playback_error_message);
    }

    player.on('error', function() {
      $('.new-player').append(options.error_message);
    });
  });
}
