export default function playReplay(videojs) {
  videojs.registerPlugin('playReplay', function() {
    var player = this;

    var applyReplayIcon = function() {
      player.addClass('replay');
    };

    var removeReplayIcon = function() {
      player.removeClass('replay');
    };

    player.on('ended', applyReplayIcon);
    player.on(['play', 'seeking'], removeReplayIcon);
  });
}
