export default function userActivityExtension(videojs) {
  (function(userActive) {
    var userInControlBar;

    videojs.getComponent('Player').prototype.userActive = function(bool) {
      var player = this;
      var controlBar = player.getChild('controlBar');

      if (userInControlBar) {
        userActive.call(player, true);
      } else {
        userActive.call(player, bool);
      }

      var onActivity = function() {
        userInControlBar = true;
      };

      var onInactivity = function() {
        userInControlBar = false;
      };

      controlBar.on('mouseenter', onActivity);
      controlBar.on('mouseleave', onInactivity);
    };
  })(videojs.getComponent('Player').prototype.userActive);
}
