export default function rearrangeButtons(videojs) {
  function rearrange(options) {
    // TODO: Find earlier event after AJAX success for this.
    this.one('loadeddata', function(e) {
      var controlBar = $(this.controlBar.el());
      var fullscreenControl = controlBar.find('.vjs-fullscreen-control');
      var resolutionButton = controlBar.find('.vjs-quality-selector');
      var volumeMenuButton = controlBar.find('.vjs-volume-panel');
      volumeMenuButton.detach().insertBefore(fullscreenControl);
      resolutionButton.detach().insertBefore(volumeMenuButton);
    });
  }

  videojs.registerPlugin('rearrangeButtons', rearrange);
}
