/**
 * This sofatutor-plugin is used for displaying timed comments in the video player.
 */

export default function timedComments(videojs) {
  videojs.registerPlugin('timedComments', function(options) {
    var player = this;
    var timedComments = JSON.parse(options.timed_comments);
    var showDurationValue = parseInt(options.show_duration);
    var markerNodes = {};
    var timedCommentObjects = {};
    var commentNodes = {};

    function createMarkerNode(timedComment) {
      var pos = 100 * timedComment.time / showDurationValue;
      return $(
        '<div class="vjs-timed-comment-dot" style="left: ' + pos + '%"></div>'
      ).get();
    }

    function addMarker(timedComment, index) {
      markerNodes[index] = createMarkerNode(timedComment);
      var vjsProgressControl = $(player.el())
        .parent()
        .find('.vjs-progress-control')
        .eq(0);
      var vjsProgressHolder = $(player.el())
        .parent()
        .find('.vjs-progress-control')
        .eq(0)
        .find('.vjs-progress-holder');
      var vjsProgressHolderClone = vjsProgressHolder
        .clone()
        .attr('class', 'vjs-progress-holder-clone')
        .empty();
      vjsProgressHolderClone.append(markerNodes[index]);
      vjsProgressControl.append(vjsProgressHolderClone);
    }

    function createTimedCommentNode(timedComment) {
      var pos = 100 * timedComment.time / showDurationValue;
      var commentTitle = 'Kommentar:';

      if (timedComment.user.flag && timedComment.user.flag.length > 0) {
        switch (timedComment.user.flag) {
          case 'tutor':
            commentTitle = 'Kommentar von unserem Tutor:';
            break;
          case 'editorial':
            commentTitle = 'Kommentar von unserer Redaktion:';
            break;
          case 'team':
            commentTitle = 'Kommentar von unserem Team:';
            break;
          default:
            return;
        }
      }

      /*jshint multistr: true */
      var commentNode = $(
        '<div class="vjs-timed-comment" style="display: none; opacity: 0; left: ' +
          pos +
          '%"> \
                   <div class="vjs-timed-comment-content"> \
                     <div class="vjs-timed-comment-user"> \
                       <img src="' +
          timedComment.user.avatar +
          '" width="45" height="45" />' +
          (timedComment.user.flag && timedComment.user.flag.length > 0
            ? '<div class="flag ' + timedComment.user.flag + '"></div>'
            : '') +
          ' \
                       <strong>' +
          timedComment.user.name +
          '</strong>' +
          (timedComment.user.degree ? timedComment.user.degree : '') +
          ' \
                     </div> \
                     <div class="vjs-timed-comment-text"> \
                       <strong>' +
          commentTitle +
          '</strong>' +
          timedComment.text +
          ' \
                     </div> \
                     <div class="vjs-timed-comments-close"></div> \
                   </div> \
                   <div class="vjs-tip"></div> \
                 </div>'
      ).get();

      $(player.el()).append(commentNode);
      $(commentNode).wrap('<div class="timed-comment-wrapper" />');
      var contentHeight = $(commentNode)
        .find('.vjs-timed-comment-content')
        .height();
      $(commentNode).height = contentHeight;

      return commentNode;
    }

    function createCommentObject(timedComment, index) {
      commentNodes[index] = createTimedCommentNode(timedComment);
      var commentNode = commentNodes[index];
      var height = $(commentNode)
        .find('.vjs-timed-comment-content')
        .height();

      var applyClass = function() {
        var availableSpaceBelow =
          $(window).scrollTop() +
          $(window).innerHeight() -
          $(player.el()).offset().top -
          $(player.el()).height();
        if (availableSpaceBelow > height + 20 && !player.isFullscreen()) {
          $(commentNode).addClass('below');
        } else {
          $(commentNode).removeClass('below');
        }
      };

      var positionComment = function() {
        var progressControlLeft = $(player.el())
          .find('.vjs-progress-control')
          .position().left;
        var markerLeft = $(markerNodes[index]).position().left;
        var commentLeft = progressControlLeft + markerLeft + 1;

        var availableSpaceBelow =
          $(window).scrollTop() +
          $(window).innerHeight() -
          $(player.el()).offset().top -
          $(player.el()).height();

        $(commentNode).show();
        var leftFromFullscreen =
          $(player.el()).offset().left -
          $(commentNode)
            .find('.vjs-timed-comment-content')
            .offset().left +
          $(commentNode)
            .find('.vjs-timed-comment-content')
            .width() /
            4;
        var height = $(commentNode)
          .find('.vjs-timed-comment-content')
          .outerHeight();
        $(commentNode).hide();

        if ($(commentNode).hasClass('below')) {
          $(commentNode).css({ bottom: -5, left: commentLeft });
        } else if (player.isFullscreen() && leftFromFullscreen > 0) {
          $(commentNode).css({ bottom: height, left: leftFromFullscreen });
        } else {
          $(commentNode).css({ bottom: height, left: commentLeft });
        }
      };

      return {
        showingTime: false,
        show: function() {
          applyClass();
          positionComment();
          return $(commentNode)
            .show()
            .animate({ opacity: 1 }, 500);
        },
        hide: function() {
          $(commentNode)
            .animate({ opacity: 0 }, 500)
            .promise()
            .done(function() {
              $(commentNode).css('display', 'none');
            });
        },
        update: function() {
          var delta = player.currentTime() - timedComment.time;
          if (delta >= 0 && delta < 5) {
            if (!this.showingTime) {
              this.showingTime = true;
              this.show();
            }
          } else if (this.showingTime) {
            this.showingTime = false;
            this.hide();
          }
        }
      };
    }

    player.ready(function() {
      if (timedComments && timedComments.length > 0) {
        timedComments.forEach(function(timedComment, index) {
          timedCommentObjects[index] = createCommentObject(timedComment, index);
          addMarker(timedComment, index);

          $(markerNodes[index]).on('mouseenter', function() {
            timedCommentObjects[index]
              .show()
              .promise()
              .done(function() {
                $(document).one('mousemove', function() {
                  timedCommentObjects[index].hide();
                });
              });
          });
          $(commentNodes[index])
            .find('.vjs-timed-comments-close')
            .on('click', function() {
              timedCommentObjects[index].hide();
            });
        });

        this.on('timeupdate', function() {
          for (var index in timedCommentObjects) {
            timedCommentObjects[index].update();
          }
        });
      }
    });
  });
}
