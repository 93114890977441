export default function playbackRatesExtension(videojs) {
  videojs.registerPlugin('playbackRatesExtension', function(options) {
    var player = this;
    player.playbackRates([0.5, 0.8, 1, 1.3, 2]);

    var playerElement = $(player.el());

    player.ready(function() {
      var playbackRateButton = player.controlBar.getChild(
        'PlaybackRateMenuButton'
      );
      var playbackRateMenuContent = $(playbackRateButton.el_).find(
        '.vjs-menu-content'
      );

      var updateRate = function() {
        // This update needs to wait until the playback rate of the player has been changed by the player API.
        setTimeout(function() {
          var playbackRate = player.playbackRate();
          var speedRateClass = ('speed-rate-' + playbackRate).replace(/\./, '');
          var selectedListElement = playerElement.find(
            "li:contains('" + playbackRate + "x')"
          );

          selectedListElement.siblings().removeClass('vjs-selected');
          selectedListElement.addClass('vjs-selected');

          playerElement.removeClass(
            'speed-rate-05 speed-rate-08 speed-rate-1 speed-rate-13 speed-rate-2'
          );
          playerElement.addClass(speedRateClass);
        });
      };

      var incrementRate = function(event) {
        player.controlBar.getChild('PlaybackRateMenuButton').handleClick(event);
        updateRate();
      };

      playbackRateButton.on('click', incrementRate);
      playbackRateMenuContent.on('click', 'li', updateRate);
      player.on('ratechange', updateRate);

      $('<div class="vjs-tip"></div>').insertAfter(playbackRateMenuContent);
      updateRate();
    });
  });
}
