/**
 * This sofatutor-plugin is used for tracking video actions like playing,
 * buffering, etc.
 */

export default function playerTracker(videojs) {
  videojs.registerPlugin('playerTracker', function(options) {
    var player = this;

    var applyPlayerTracker = options.apply_player_tracker;
    var trackedLabel = options.tracked_label;
    var dimensions = options.dimensions;

    // analytics tracking hook
    if (applyPlayerTracker) {
      Sofatutor.trackPlayer.setup(player, trackedLabel, dimensions);
    }
  });
}
