export default function toggleCaptions(videojs) {
  videojs.registerPlugin('toggleCaption', function() {
    var player = this;

    this.ready(function() {
      var toggleCaptions = player
        .getChild('controlBar')
        .getChild('SubsCapsButton');

      $(toggleCaptions.el_).append('<div class="vjs-tip"></div>');
    });
  });
}
