export default function resizeHandle(videojs) {
  videojs.registerPlugin('resizeHandle', function() {
    var player = this;

    var addOrRemoveSizeClasses = function(sizes) {
      var width = sizes.width;
      var height = sizes.height;
      var widthDelta = Math.abs(290 - width);
      var heightDelta = Math.abs(163 - height);

      /* These are not exact values they have an intentional error tolerance
      * The OR condition is a hack since player has correct width but
      * height 0 immediately after leaving fullscreen */
      if (widthDelta < 4 || heightDelta < 4) {
        $(player.el()).addClass('launchpad-small-player');
      } else {
        $(player.el()).removeClass('launchpad-small-player');
      }
    };

    player.ready(function() {
      addOrRemoveSizeClasses({
        width: $(player.el()).width(),
        height: $(player.el()).height()
      });
    });

    player.on('fullscreenchange', function() {
      // Fix control bar is outside of screen on android devices with virtual back button
      var isAndroid = /(android)/i.test(navigator.userAgent);

      if (player.isFullscreen()) {
        $(player.el()).removeClass(
          'videolist-small-player launchpad-small-player'
        );

        if (isAndroid) {
          $('.vjs-control-bar').css('position', 'fixed');
        }
      } else {
        addOrRemoveSizeClasses({
          width: $(player.el()).width(),
          height: $(player.el()).height()
        });

        if (isAndroid) {
          $('.vjs-control-bar').css('position', 'absolute');
        }
      }
    });

    player.on('playerresize', function() {
      addOrRemoveSizeClasses({
        width: $(player.el()).width(),
        height: $(player.el()).height()
      });
    });
  });
}
