export default function loadingSpinnerExtension(videojs) {
  videojs.registerPlugin('loadingSpinnerExtension', function() {
    var player = this;

    player.on('ready', function() {
      var loadingSpinner = player.getChild('loadingSpinner');

      var showSpinner = function() {
        $(loadingSpinner.el()).css('display', 'block');
      };

      var hideSpinner = function() {
        $(loadingSpinner.el()).css('display', 'none');
      };

      player.on(['canplay', 'seeking', 'error', 'waiting'], showSpinner);
      player.on(['canplaythrough', 'playing', 'seeked', 'ended'], hideSpinner);
    });
  });
}
