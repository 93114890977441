export default function setLanguage(videojs) {
  const englishLanguage = {
    "Play": "Play",
    "Pause": "Pause",
    "Fullscreen": "Fullscreen",
    "Non-Fullscreen": "Non-Fullscreen",
    "You aborted the media playback": "You aborted the media playback",
    "A network error caused the media download to fail part-way.": "A network error caused the media download to fail part-way.",
    "The media could not be loaded, either because the server or network failed or because the format is not supported.": "A network error caused the media download to fail part-way.",
    "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.",
    "No compatible source was found for this media.": "No compatible source was found for this media."
  };

  const germanLanguage = {
    "Play": "Abspielen",
    "Pause": "Pause",
    "Fullscreen": "Vollbild",
    "Non-Fullscreen": "Vollbild verlassen",
    "You aborted the media playback": "Sie haben die Videowiedergabe abgebrochen.",
    "A network error caused the media download to fail part-way.": "Der Videodownload ist aufgrund eines Netzwerkfehlers fehlgeschlagen.",
    "The media could not be loaded, either because the server or network failed or because the format is not supported.": "Das Video konnte nicht geladen werden, da entweder ein Server- oder Netzwerkfehler auftrat oder das Format nicht unterstützt wird.",
    "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": "Die Videowiedergabe wurde entweder wegen eines Problems mit einem beschädigten Video oder wegen verwendeten Funktionen, die vom Browser nicht unterstützt werden, abgebrochen.",
    "No compatible source was found for this media.": "Für dieses Video wurde keine kompatible Quelle gefunden."
  };

  videojs.addLanguage("us", englishLanguage); // This is the default language, so probably this is redundant.
  videojs.addLanguage("de", germanLanguage);
  videojs.addLanguage("ch", germanLanguage);
  videojs.addLanguage("at", germanLanguage);

  videojs.registerPlugin('setLanguage', function(options) {
    this.language(options.language);
  });
}
