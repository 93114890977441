/**
 * This sofatutor-plugin is used for showing video hints for exercises in the
 *   video player.
 */

export default function videoHint(videojs) {
  videojs.registerPlugin('videoHint', function() {
    var player = this;

    var SeekBar = videojs.getComponent('SeekBar');

    var PlayProgressComponent = SeekBar.getComponent('PlayProgressBar');
    var SegmentBarBase = videojs.extend(PlayProgressComponent, {
      constructor: function(player, options) {
        PlayProgressComponent.call(this, player, options);
        this.on(player, 'playSegment', function() {
          this.update();
        });
      },

      createEl: function() {
        var el = document.createElement('div');
        el.setAttribute('class', 'vjs-segment-base');
        return el;
      },

      update: function() {
        var segmentWidth =
          player.segmentEndPercent() - player.segmentStartPercent();
        this.el().style.left =
          Math.round(player.segmentStartPercent() * 100, 2) + '%';
        this.el().style.width = Math.round(segmentWidth * 100, 2) + '%';
      }
    });
    SeekBar.registerComponent('SegmentBarBase', SegmentBarBase);

    var SegmentLoadProgressBar = videojs.extend(PlayProgressComponent, {
      constructor: function(player, options) {
        PlayProgressComponent.call(this, player, options);
        this.on(player, 'timeupdate', function() {
          this.update();
        });
      },

      createEl: function() {
        var el = document.createElement('div');
        el.setAttribute('class', 'vjs-segment-load-progress');
        return el;
      },

      update: function() {
        var segmentWidth =
          player.segmentEndPercent() - player.segmentStartPercent();
        var progress = Math.min(
          (player.bufferedPercent() - player.segmentStartPercent()) /
            segmentWidth,
          1
        );

        this.el().style.width = Math.round(progress * 100, 2) + '%';
      }
    });
    SeekBar.registerComponent('SegmentLoadProgressBar', SegmentLoadProgressBar);

    var SegmentPlayProgressBar = videojs.extend(PlayProgressComponent, {
      constructor: function(player, options) {
        PlayProgressComponent.call(this, player, options);
        this.on(player, 'timeupdate', function() {
          this.update();
        });
      },

      createEl: function() {
        var el = document.createElement('div');
        el.setAttribute('class', 'vjs-segment-play-progress');
        return el;
      },

      update: function() {
        var absoluteSegmentPlayProgress = Math.max(
          player.currentTime() - player.segmentStart(),
          0
        );
        var segmentPlayProgressFraction =
          absoluteSegmentPlayProgress /
          (player.segmentEnd() - player.segmentStart());
        var playProgress =
          Math.round(segmentPlayProgressFraction * 100, 2) + '%';

        this.el().style.width = playProgress;
      }
    });
    SeekBar.registerComponent('SegmentPlayProgressBar', SegmentPlayProgressBar);

    player.segmentStart = function() {
      return this.isPlayingSegment() ? this.options.startAt : 0;
    };

    player.segmentEnd = function() {
      return this.isPlayingSegment()
        ? this.options.endAt
        : Number.POSITIVE_INFINITY;
    };

    player.segmentStartPercent = function() {
      return this.duration() ? this.segmentStart() / this.duration() : 0;
    };

    player.segmentEndPercent = function() {
      return this.duration() ? this.segmentEnd() / this.duration() : 1;
    };

    player.ready(function() {
      var seekBarInstance = player
        .getChild('controlBar')
        .getChild('progressControl')
        .getChild('seekBar');
      var segmentBarInstance = seekBarInstance.addChild('SegmentBarBase');
      segmentBarInstance.addChild('SegmentLoadProgressBar');
      segmentBarInstance.addChild('SegmentPlayProgressBar');
    }),
      (player.isPlayingSegment = function() {
        return player.playingSegment;
      });

    player.isAtSegmentEndTime = function() {
      var currentTime = player.currentTime();
      return (
        player.options.endAt &&
        currentTime >= player.options.endAt &&
        currentTime <= player.options.endAt + 1
      );
    };

    player.outOfSegment = function() {
      var currentTime = player.currentTime();
      return (
        player.options.startAt > currentTime ||
        currentTime > player.options.endAt + 1
      );
    };

    player.clearSegment = function() {
      player.playingSegment = false;
      $(player.el()).removeClass('playing-segment');
      player.off('timeupdate', player.stopSegmentAtEndTime);
    };

    player.stopSegmentAtEndTime = function() {
      if (player.isAtSegmentEndTime()) {
        const segmentEndedEvent = new CustomEvent('segmentEnded');
        if (document) {
          document.dispatchEvent(segmentEndedEvent);
        }
        player.pause();
      } else {
        if (player.outOfSegment() && player.isPlayingSegment()) {
          player.clearSegment();
        }
      }
    };

    player.playSegment = function(startAt, endAt) {
      player.stopSegment();

      if (typeof startAt !== 'undefined') player.options.startAt = startAt;
      if (typeof endAt !== 'undefined') player.options.endAt = endAt;
      player.playingSegment = true;
      player.trigger('playSegment');

      player.one('startAt', function() {
        player.on('timeupdate', player.stopSegmentAtEndTime);
        player.on('stopSegment', function() {
          player.clearSegment();
        });
      });

      player.pause();
      player.one('startAt', function() {
        player.play();
      });
      player.startAt(startAt);
    };

    player.stopSegment = function() {
      player.playingSegment = false;
      player.trigger('stopSegment');
    };

    player.startAt = function(time) {
      player.currentTime(time);
      player.trigger('startAt');
    };

    player.replaySegment = function() {
      player.currentTime(player.options.startAt);
      player.play();
      $(player.el()).addClass('playing-segment');
    };

    player.onPlaySegment = function(event) {
      $(player.el()).addClass('playing-segment');
    };

    player.onStopSegment = function(event) {
      $(player.el()).removeClass('playing-segment');
    };

    player.on('playSegment', this.onPlaySegment),
      player.on('stopSegment', this.onStopSegment);
  });
}
