import videojs from 'video.js';

import bigPlayToggle from '../videojs/plugins/big_play_toggle.js';
import errorMessages from '../videojs/plugins/error_messages.js';
import hideOnFullscreen from '../videojs/plugins/hide_on_fullscreen.js';
import hotkeysSetup from '../videojs/plugins/hotkeys_setup.js';
import loadingSpinnerExtension from '../videojs/plugins/loading_spinner_extension.js';
import playReplay from '../videojs/plugins/play_replay.js';
import playbackRatesExtension from '../videojs/plugins/playback_rates_extension.js';
import playerAppearsWhenReady from '../videojs/plugins/player_appears_when_ready.js';
import playerTracker from '../videojs/plugins/player_tracker.js';
import rearrangeButtons from '../videojs/plugins/rearrange_buttons.js';
import resizeHandle from '../videojs/plugins/resize_handle.js';
import setLanguage from '../videojs/plugins/set_language.js';
import showFullDuration from '../videojs/plugins/show_full_duration.js';
import sofatutorWatermark from '../videojs/plugins/sofatutor_watermark.js';
import timeFormat from '../videojs/plugins/time_format.js';
import timedComments from '../videojs/plugins/timed_comments.js';
import userActivityExtension from '../videojs/plugins/user_activity_extension.js';
import videoHint from '../videojs/plugins/video_hint.js';
import videoViewTracker from '../videojs/plugins/video_view_tracker.js';
import videoJsResolutionSwitcherExtension from '../videojs/plugins/videojs_resolution_switcher_extension.js';
import videoJsConfiguration from '../videojs/plugins/videojs_configuration.js';
import volumeBarTip from '../videojs/plugins/volume_bar_tip.js';
import fixPlayPauseToggle from '../videojs/plugins/fix_play_pause_toggle.js';
import toggleCaptions from '../videojs/plugins/toggle_captions.js';

bigPlayToggle(videojs);
errorMessages(videojs);
hideOnFullscreen(videojs);
hotkeysSetup(videojs);
loadingSpinnerExtension(videojs);
playReplay(videojs);
playbackRatesExtension(videojs);
playerAppearsWhenReady(videojs);
playerTracker(videojs);
rearrangeButtons(videojs);
resizeHandle(videojs);
setLanguage(videojs);
showFullDuration(videojs);
sofatutorWatermark(videojs);
timeFormat(videojs);
timedComments(videojs);
userActivityExtension(videojs);
videoHint(videojs);
videoViewTracker(videojs);
videoJsResolutionSwitcherExtension(videojs);
videoJsConfiguration(videojs);
volumeBarTip(videojs);
fixPlayPauseToggle(videojs);
toggleCaptions(videojs);

window.videojs = videojs;
